import React, { useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';

import { toObservedLoadingComponent } from '~/app/components/ItemPage/LoadingObserver';
import ItemPageHeaderContent from '~/app/components/ItemPage/ItemPageHeaderContent';
import useUnpublishLivePage from '~/app/components/ItemPage/useUnpublishLivePage';
import deleteCustomPageAction from '~/app/lib/store/customPages/actions/delete';
import { PageSectionTypes } from '~/app/components/ItemPage/sections/types';
import ItemPageMetadata from '~/app/components/ItemPage/ItemPageMetadata';
import useSelectCustomPage from '~/app/lib/hooks/useSelectCustomPage';
import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import { ItemPageProps } from '~/app/components/ItemPage/types';
import useSelectArtist from '~/app/lib/hooks/useSelectArtist';
import { PageTrackerContext } from '~/app/components/Page';
import { useDispatch } from '~/app/lib/store/redux';
import ItemPage from '~/app/components/ItemPage';
import { useAppRouter } from '~/app/lib/router2';
import { ItemTypes, PageTypes } from '~/types';
import { useI18n } from '~/app/lib/i18n';

import {
  useAppLoading,
  useAppToast,
} from '~/app/components/NextApp/lib/CoreUi';

import useCustomPageContext from './useCustomPageContext';
import toStructuredData from './toStructuredData';
import { AddonTypes } from '~/app/lib/songwhipApi/types';

const OrchardLegalFooterDynamic = dynamic(
  () => import('~/app/components/ItemPage/OrchardLegalFooter'),
  { ssr: true }
);

const VideosSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/VideosSection')
);

const MerchSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/MerchSection'),
  {
    loading: toObservedLoadingComponent(),
  }
);

const ShowsSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/ShowsSection'),
  {
    loading: toObservedLoadingComponent(),
  }
);

const PAGE_SECTION_COMPONENTS = {
  [PageSectionTypes.VIDEOS]: VideosSectionDynamic,
  [PageSectionTypes.MERCH]: MerchSectionDynamic,
  [PageSectionTypes.SHOWS]: ShowsSectionDynamic,
};

export interface CustomPageProps
  extends Pick<
    ItemPageProps,
    'headerToolbarHeight' | 'renderBeforeHeader' | 'withRedirectIfNeeded'
  > {
  customPageId: number;
}

const CustomPage = ({
  customPageId,
  renderBeforeHeader,
  headerToolbarHeight,
  withRedirectIfNeeded,
}: CustomPageProps) => {
  const { t } = useI18n();

  const customPage = useSelectCustomPage(customPageId)!;
  const itemContext = useCustomPageContext({ customPage })!;
  const isLargeScreen = useIsLargeScreen();
  const artist = useSelectArtist(customPage.artistId);
  const dispatch = useDispatch();
  const showToast = useAppToast();
  const router = useAppRouter();
  const unpublishPage = useUnpublishLivePage(ItemTypes.CUSTOM_PAGE);

  useAppLoading()(customPage.isLoading);

  const showcaseBackground =
    itemContext?.addons[AddonTypes.THEME]?.showcaseBackground;

  const heroHeight = showcaseBackground ? (isLargeScreen ? '28vh' : '84vw') : 0;

  return (
    <ItemPage
      key={customPageId}
      pageType={PageTypes.CUSTOM}
      itemContext={itemContext}
      hasContent={Boolean(customPage.name)} // TODO: Add useful check
      testId="customPage"
      displayType="custom"
      error={customPage.error}
      isLoading={!!customPage.isLoading}
      isOwned={customPage.isOwned}
      isDraft={customPage.isDraft}
      isOrchardPage={customPage.isOwned}
      pageBrand={customPage.pageBrand}
      userCanEdit={!!customPage.userCanEdit}
      pageOwnedByAccountIds={customPage.ownedByAccountIds}
      trackerContext={useMemo(
        (): PageTrackerContext => ({
          artistId: customPage.artistId,
          artistName: customPage.artistName,

          customPageId: customPage.id,
          customPageName: customPage.name,
        }),
        [customPage]
      )}
      pageSectionComponents={PAGE_SECTION_COMPONENTS}
      heroHeight={heroHeight}
      withRedirectIfNeeded={withRedirectIfNeeded}
      headerToolbarHeight={headerToolbarHeight}
      backgroundHeight={isLargeScreen ? '100vh' : '105vw'}
      shareText={customPage.name}
      pagePath={customPage.pagePath}
      artistPagePath={customPage.artistPagePath}
      toActionItems={useCallback(
        ({ sections }) => {
          if (customPage.userCanEdit) {
            sections[0].push({
              content: t('item.actions.viewInDashboard'),
              href: `/dashboard/custom/${customPage.id}`,
              icon: 'chart',
              testId: 'viewInDashboard',
            });

            // don't allow unpublish draft page
            if (!customPage.isDraft) {
              sections[sections.length - 1].push({
                testId: 'unpublishPage',
                content: t('item.actions.unpublishPage'),
                icon: 'unpublish',
                onClick: () => {
                  unpublishPage({
                    itemId: customPage.id,
                    itemName: customPage.name,
                    pagePath: customPage.pagePath,
                  });
                },
              });
            }

            sections[sections.length - 1].push({
              content: t('item.actions.deletePage'),
              testId: 'deletePage',
              icon: 'delete',

              onClick: async () => {
                await dispatch(deleteCustomPageAction(customPage.id));

                await showToast({
                  text: t('item.events.customPageDeleted'),
                });

                router.push('/dashboard');
              },
            });
          }

          if (artist) {
            sections.push([
              {
                content: artist.name,
                href: artist.pagePath,
                icon: 'person',
              },
            ]);
          }

          return sections;
        },
        [customPage, artist]
      )}
      renderBeforeHeader={renderBeforeHeader}
      renderHeaderContent={useCallback(
        () =>
          !isLargeScreen ? (
            <ItemPageHeaderContent
              title={customPage.name}
              subtitle={customPage.artistName}
            />
          ) : null,
        [isLargeScreen, customPage]
      )}
      renderFooterContent={useCallback(() => {
        if (customPage.isOwned) {
          return <OrchardLegalFooterDynamic />;
        }
      }, [])}
      renderContent={useCallback(() => {
        return (
          <ItemPageMetadata
            error={customPage.error}
            pagePath={customPage.pagePath}
            itemContext={itemContext}
            isOwned={customPage.isOwned}
            defaultTitle={customPage.name}
            defaultImage={customPage.image}
            defaultDescription={customPage.name}
            // REVIEW: this could be a <StructuredData> component instead
            // of having to pass props down the tree to <PageMetaData>
            // which is getting quite overloaded now
            toStructuredData={(params) =>
              // @ts-ignore
              toStructuredData({
                ...params,
                customPageName: customPage.name,
                artistName: customPage.artistName,
                artistPagePath: customPage.artistPagePath,
              })
            }
          />
        );
      }, [customPage])}
    />
  );
};

export default CustomPage;
