import { useMemo } from 'react';

import { selectUserCountry } from '~/app/lib/store/session/selectors';
import { SelectedAlbum } from '~/app/lib/store/albums/types';
import { AddonTypes } from '~/app/lib/songwhipApi/types';
import { useSelector } from '~/app/lib/store/redux';
import { useI18n } from '~/app/lib/i18n';
import { ServiceTypes } from '~/types';

import useSharedArtistComponents from '../ItemPageArtist/useSharedArtistComponents';
import { PageSectionTypes } from '~/app/components/ItemPage/sections/types';
import {
  toStreamLinksSectionPreset,
  toBuyLinksSectionPreset,
} from '~/app/components/ItemPage/sections/ItemLinks/presets';

import { ItemContext, PresetTypes } from '~/app/components/ItemPage/types';

import { AlbumItemContext } from './types';

const DEFAULT_PRERELEASE_LAYOUT = [
  {
    component: PageSectionTypes.PAGE_TITLE,
  },
  {
    preset: PresetTypes.SUBSCRIBE_TO_RELEASE_BUTTONS,
  },
];

const DEFAULT_LIVE_LAYOUT = [
  {
    component: PageSectionTypes.PAGE_TITLE,
  },
  {
    preset: PresetTypes.STREAM_LINKS,
  },
  {
    preset: PresetTypes.BUY_LINKS,
  },
];

const useAlbumItemContext = ({
  album,
  withAllLinks,
  originalItemContext,
}: {
  album: SelectedAlbum;
  withAllLinks?: boolean;

  /**
   * When in Edit Mode we pass the original ItemContext and make a copy which we
   * render in the view and make edits to. This allows us to compare the 'original'
   * with the 'active' to see what has changed.
   */
  originalItemContext?: ItemContext;
}) => {
  const { t } = useI18n();
  const userCountry = useSelector(selectUserCountry);
  const sharedComponents = useSharedArtistComponents(album.artistId);
  const { config } = album;

  const addons = album.config?.addons || {};

  // show showcase background by default
  addons[AddonTypes.THEME] = {
    showcaseBackground: true,
    ...addons[AddonTypes.THEME],
  };

  return useMemo<AlbumItemContext>((): AlbumItemContext => {
    return {
      presets: {
        [PresetTypes.STREAM_LINKS]: toStreamLinksSectionPreset({
          title: t('item.actions.streamAlbumOn'),
          country: withAllLinks ? 'ALL' : userCountry,
        }),

        [PresetTypes.BUY_LINKS]: toBuyLinksSectionPreset({
          title: t('item.actions.buyAlbumOn'),
        }),

        [PresetTypes.PRESAVE_BUTTONS]: {
          component: PageSectionTypes.PRESAVE_BUTTONS,

          props: {
            title: t('item.actions.presaveRelease'),

            items: [
              {
                type: ServiceTypes.SPOTIFY,
              },
              { type: ServiceTypes.ITUNES },
              { type: ServiceTypes.DEEZER },
            ],
          },
        },

        [PresetTypes.SUBSCRIBE_TO_RELEASE_BUTTONS]: {
          component: PageSectionTypes.PRESAVE_BUTTONS_2,

          props: {
            title: t('item.actions.getNotifiedWhenReleaseLiveOn'),
            items: undefined,
          },
        },
      },

      data: {
        item: album,
        custom: config?.objects,
      },

      layout: {
        main: toLayout(album),
      },

      config: {
        showArtistIds: config?.showArtistIds || album.artistIds,
      },

      shared: sharedComponents,
      addons,

      originalItemContext,
    };
  }, [album, config, sharedComponents, originalItemContext]);
};

const toLayout = (album: SelectedAlbum) => {
  const defaultLayout = album.isPrerelease
    ? DEFAULT_PRERELEASE_LAYOUT
    : DEFAULT_LIVE_LAYOUT;

  // use custom layout if defined, otherwise use the default layout
  return album.config?.layout?.main || defaultLayout;
};

export default useAlbumItemContext;
